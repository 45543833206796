<template>
  <div id="trend-chart" style="height: 210px; width: 445px"></div>
</template>

<script>
import { getAlertTrend } from '@/api/alert'
import moment from 'moment'

let chart

export default {
  name: 'AlertTrendChart',
  data () {
    return {
      option: {
        grid: {
          top: 20,
          right: 30,
          bottom: 30,
          left: 70
        },
        tooltip: {
          trigger: 'axis',
          backgroundColor: '#0b2d55c0',
          borderColor: '#0b2d55c0',
          textStyle: {
            color: '#aec3dc',
            fontSize: 13
          },
          axisPointer: {
            type: 'line',
            lineStyle: {
              type: 'dashed',
              color: '#32346c'
            }
          },
          formatter: (params) => {
            let htmlStr = `<div>${params[0].axisValue}</div>`
            params.forEach(item => {
              htmlStr += `
                 <div>
                  ${item.marker}
                  <span>${this.$t(`alert_severity.${item.dimensionNames[item.componentIndex + 1]}`)}：${item.value[item.componentIndex + 1]}</span>
                </div>
              `
            })
            return htmlStr
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          axisLabel: {
            interval: 0,
            margin: 12,
            color: '#aec3dc',
            fontSize: 13,
            formatter: (v) => {
              return moment(v).format('MM-DD')
            }
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          boundaryGap: false,
          minInterval: 1,
          splitNumber: 3,
          min: 0,
          axisTick: { show: false },
          axisLine: {
            show: false
          },
          axisLabel: {
            color: '#aec3dc',
            fontSize: 13
          },
          splitLine: {
            lineStyle: {
              color: '#32346c',
              type: 'dashed'
            }
          }
        },
        dataset: { source: [] },
        series: []
      },
      alertSeverity: ['disaster', 'high', 'average', 'warning'],
      dateList: [],
      source: [],
      color: ['#FF5B73', '#FAAD14', '#096DD9', '#38D3FA', '#99da69'],
      series: [],
      timer: null,
      tooltipTimer: null
    }
  },
  mounted () {
    this.initChart()
    this.refresh()
  },
  methods: {
    refresh () {
      this.fetch()
      this.timer = setInterval(() => {
        this.fetch()
      }, 1000 * 60 * 5)
    },
    initChart () {
      this.series = []
      for (let i = 0; i < this.alertSeverity.length; i++) {
        this.series.push({
          type: 'line',
          seriesLayoutBy: 'row',
          smooth: true,
          showSymbol: false,
          symbol: 'circle',
          symbolSize: 3,
          areaStyle: {
            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: this.color[i]
            }, {
              offset: 0.8,
              color: 'rgba(255,255,255,0)'
            }], false),
            shadowBlur: 10,
            opacity: 0.3
          },
          itemStyle: {
            color: this.color[i],
            lineStyle: {
              width: 1.5,
              type: 'solid'
            },
            borderColor: this.color[i],
            borderWidth: 8,
            barBorderRadius: 0,
            label: {
              show: false
            },
            opacity: 0.5
          }
        })
      }
      chart = this.$echarts.init(document.getElementById('trend-chart'))
    },
    fetch () {
      if (this.tooltipTimer) clearInterval(this.tooltipTimer)
      getAlertTrend({
        trend_of: 'severity',
        datetime_from: moment().subtract(6, 'day').format('YYYY-MM-DD HH:mm'),
        datetime_to: moment().format('YYYY-MM-DD HH:mm'),
        source_type: 'network_device'
      }).then(res => {
        const chartData = res.data.data
        this.dateList = []
        this.source = []
        for (let i = 0; i < 7; i++) {
          this.dateList.unshift(
            moment().subtract(i, 'day').format('YYYY-MM-DD')
          )
        }
        this.dateList = ['name', ...this.dateList]

        for (let i = 0; i < this.alertSeverity.length; i++) {
          const severity = this.alertSeverity[i]
          if (this.source.length <= i) this.source.push([severity])
          for (let j = 1; j < this.dateList.length; j++) {
            const date = this.dateList[j]
            this.source[i].push(
              chartData.find(
                item => {
                  return item.date === date && item.name === severity
                }
              )
                ? chartData.find(
                  item => item.date === date && item.name === severity
                ).value
                : 0
            )
          }
        }

        this.source.unshift(this.dateList)
        this.option = { ...this.option, dataset: { source: this.source }, series: this.series }
        chart.setOption(this.option)

        let currentIndex = -1
        this.tooltipTimer = setInterval(() => {
          const dataLen = this.dateList.length
          // 取消之前高亮的图形
          chart.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
            dataIndex: currentIndex
          })
          currentIndex = (currentIndex + 1) % dataLen
          // 高亮当前图形
          chart.dispatchAction({
            type: 'highlight',
            seriesIndex: 0,
            dataIndex: currentIndex
          })
          // 显示 tooltip
          chart.dispatchAction({
            type: 'showTip',
            seriesIndex: 0,
            dataIndex: currentIndex
          })
        }, 2000)
      })
    }
  },
  destroyed () {
    if (this.timer) clearInterval(this.timer)
    if (this.tooltipTimer) clearInterval(this.tooltipTimer)
    if (chart) chart.dispose()
  }
}
</script>
