<template>
  <div id="alert-module">
    <div style="height: 123px; margin-left: 14px; padding-top: 13px;">
      <div class="alert-count-container">
        <img :src="require('@/assets/images/network-screen/4.png')" width="100" height="100">
        <div class="value-container">
          <p class="alert-count-title">今日告警</p>
          <p class="alert-count-value">{{ dayAlertCount }}</p>
        </div>
      </div>
      <div class="alert-count-container" style="margin-left: 54px;">
        <img :src="require('@/assets/images/network-screen/4.png')" width="100" height="100">
        <div class="value-container">
          <p class="alert-count-title">7 日告警</p>
          <p class="alert-count-value">{{ weekALertCount }}</p>
        </div>
      </div>
    </div>
    <little-header title="未恢复告警" titleWidth="83px"></little-header>
    <div style="height: 257px; padding: 22px 13px 0 15px;">
      <alert-table></alert-table>
    </div>
    <little-header title="告警统计数量Top10" titleWidth="147px"></little-header>
    <alert-count-top></alert-count-top>
  </div>
</template>

<script>
import '@/fonts/font.css'
import { getAlertCount } from '@/api/alert'
import LittleHeader from '@/components/LittleHeader'
import AlertTable from './modules/AlertTable'
import AlertCountTop from './modules/AlertCountTop'
import moment from 'moment'

export default {
  name: 'AlertModule',
  components: {
    LittleHeader,
    AlertTable,
    AlertCountTop
  },
  data () {
    return {
      dayAlertCount: 0,
      weekALertCount: 0,
      timer: null
    }
  },
  beforeDestroy () {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
  },
  mounted () {
    this.refresh()
  },
  methods: {
    refresh () {
      this.fetch()
      this.timer = setInterval(() => {
        this.fetch()
      }, 1000 * 60 * 5)
    },
    fetch () {
      this.dayAlertCount = 0
      this.weekALertCount = 0
      const params1 = {
        source_type: 'network_device',
        datetime_from: moment().format('YYYY-MM-DD HH:mm'),
        datetime_to: moment().format('YYYY-MM-DD HH:mm')
      }
      getAlertCount(params1).then(res => {
        res.data.data.forEach(item => {
          this.dayAlertCount += item.value
        })
      })
      const params2 = {
        source_type: 'network_device',
        datetime_from: moment().subtract(6, 'day').format('YYYY-MM-DD HH:mm'),
        datetime_to: moment().format('YYYY-MM-DD HH:mm')
      }
      getAlertCount(params2).then(res => {
        res.data.data.forEach(item => {
          this.weekALertCount += item.value
        })
      })
    }
  }
}
</script>

<style lang="less">
#alert-module {
  .alert-count-container {
    float: left;
    position: relative;

    .value-container{
      float: right;
      padding: 22px 0 0 11px;

      .alert-count-title {
        font-size: 16px;
        font-family: HanSans;
        color: #A2B7D9;
      }

      .alert-count-value {
        position: relative;
        font-size: 24px;
        font-family: D-DIN;
        font-weight: bold;
        color: #DCBD73;
        background: linear-gradient(0deg, rgba(3,0,0,0.29) 0%, rgba(255,255,255,0.29) 100%);
        -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
      }
    }
  }
}
</style>
