<template>
  <div class="node-tooltip-content">
    <header>
      <div class="title overflow-ellipsis">{{ nodeData.name }}</div>
      <a @click="close" style="color: #54bcff"><a-icon type="close" /></a>
    </header>

    <div class="body overflow-scroll-hidden">
      <div class="module-header-fs14 small-header">
        基本信息
        <div style="float: right; margin-top: -2px">
          <a
            style="font-size: 11px; color: #54bcffb0; line-height: 16px"
            @click="showMore = !showMore"
            >{{ showMore ? '折叠' : '查看全部' }}</a
          >
        </div>
      </div>
      <a-descriptions v-if="detail" :column="2" style="padding: 0 8px">
        <a-descriptions-item label="状态">
          <source-status-tag :status="detail.status"></source-status-tag>
        </a-descriptions-item>
        <a-descriptions-item label="所属群组">
          <a-tag v-for="group in detail.groups" :key="group.id">
            {{ group.name }}
          </a-tag>
        </a-descriptions-item>
        <a-descriptions-item label="制造商">
          {{ $t(`hardware_vendor.${detail.vendor}`) }}
        </a-descriptions-item>
        <a-descriptions-item label="IP 地址">
          {{ detail.monitor_address }}
        </a-descriptions-item>
        <a-descriptions-item label="监控端口">
          {{ detail.monitor_port }}
        </a-descriptions-item>
        <a-descriptions-item label="创建时间" :span="2" v-if="showMore">
          {{ detail.created_at }}
        </a-descriptions-item>
        <a-descriptions-item label="更新时间" :span="2" v-if="showMore">
          {{ detail.updated_at }}
        </a-descriptions-item>
        <a-descriptions-item label="SNMP 版本" :span="2" v-if="showMore">
          {{ $t(`snmp_version.${detail.snmp_version}`) }}
        </a-descriptions-item>
        <a-descriptions-item label="型号" :span="2" v-if="showMore">
          {{ detail.model }}
        </a-descriptions-item>
        <a-descriptions-item label="监控状态" :span="2" v-if="showMore">
          <monitor-status-tag
            v-for="item in monitorStatuses"
            :key="item.name"
            :status="item.value"
            :name="item.name"
          ></monitor-status-tag>
        </a-descriptions-item>
      </a-descriptions>

      <div class="module-header-fs14 small-header">
        当前告警 ({{ alertTotal }})
      </div>
      <div v-if="alertDetail.length">
        <div
          class="alert-item-container"
          v-for="(item, index) in alertDetail"
          :key="item.created_at + index"
        >
          <div class="content">
            <div class="info-container">
              <div style="max-height: 49px">
                <a-tooltip :title="item.name">
                  {{ item.name }}
                </a-tooltip>
              </div>
            </div>
            <div class="datetime-container">
              <div class="datetime-text">{{ item.triggered_at }}</div>
              <div class="description-text">
                {{ `持续${duration(item.triggered_at, item.recovered_at)}` }}
              </div>
            </div>
          </div>
          <div class="severity-tag">
            <alert-severity-tag :severity="item.severity"></alert-severity-tag>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { humanAlertDuration } from '@/utils'
import {
  getNetworkDevice,
  getNetworkDeviceMonitorStatusList
} from '@/api/network-device'
import { getServer, getServerMonitorStatusList } from '@/api/server'
import { getStorage, getStorageMonitorStatusList } from '@/api/storage'
import {
  getHypervisorList,
  getHypervisor
} from '@/api/hypervisor'
import { getAlertList } from '@/api/alert'
import SourceStatusTag from '@/components/tag/SourceStatusTag'
import AlertSeverityTag from '@/components/tag/AlertSeverityTag'
import MonitorStatusTag from '@/components/tag/MonitorStatusTag'

export default {
  name: 'NodeTooltip',
  props: {
    selectNode: {}
  },
  components: {
    SourceStatusTag,
    AlertSeverityTag,
    MonitorStatusTag
  },
  computed: {
    nodeData () {
      if (this.selectNode) return this.selectNode.getData()
      return {}
    },
    getFunc () {
      if (this.nodeData.sourceType === 'server') return getServer
      if (this.nodeData.sourceType === 'storage') return getStorage
      if (this.nodeData.sourceType === 'hypervisor') return getHypervisor
      return getNetworkDevice
    },
    getMonitorFunc () {
      if (this.nodeData.sourceType === 'server') {
        return getServerMonitorStatusList
      }
      if (this.nodeData.sourceType === 'storage') {
        return getStorageMonitorStatusList
      }
      if (this.nodeData.sourceType === 'hypervisor') {
        return getHypervisorList
      }
      return getNetworkDeviceMonitorStatusList
    }
  },
  data () {
    return {
      detail: undefined,
      alertDetail: [],
      alertTotal: 0,
      params: {
        page: 1,
        pageSize: 10
      },
      monitorStatuses: [],
      showMore: false
    }
  },
  mounted () {
    if (this.selectNode) {
      this.fetch()
      this.fetchAlerts()
    }
  },
  methods: {
    close () {
      this.$emit('closeNodeTooltip')
    },
    duration (triggeredAt, recoveredAt) {
      return humanAlertDuration(triggeredAt, recoveredAt)
    },
    fetch () {
      this.getFunc(this.nodeData.sourceId)
        .then(res => {
          this.detail = res.data
        })
        .catch(() => {})
      this.getMonitorFunc(this.nodeData.sourceId)
        .then(res => {
          this.monitorStatuses = res.data.data
        })
        .catch(() => {})
    },
    fetchAlerts () {
      getAlertList({
        recovered: false,
        page: this.params.page,
        page_size: this.params.pageSize,
        source_id: this.nodeData.sourceId
      })
        .then(res => {
          this.alertDetail.push(...res.data.data)
          this.alertTotal = res.data.total
          if (this.params.page * this.params.pageSize < this.alertTotal) {
            this.params.page++
            this.fetchAlerts()
          }
        })
        .catch(() => {})
    }
  },
  watch: {
    selectNode (v) {
      this.params.page = 1
      this.alertTotal = 0
      this.alertDetail = []
      if (v) {
        this.fetch()
        this.fetchAlerts()
      }
    }
  }
}
</script>

<style lang="less">
.node-tooltip-content {
  header {
    display: flex;
    padding-top: 3.5px;
    line-height: 32px;
    font-size: 14px;
    .title {
      width: 284px;
      height: 32px;
      text-align: center;
      line-height: 32px;
      color: #d1e9ff;
      font-weight: 500;
      padding: 0 8px;
      letter-spacing: 1px;
    }
  }

  .body {
    height: 350px;
    overflow: auto;
    // margin-top: 8px;
    .ant-descriptions-item {
      padding-bottom: 6px;

      .ant-descriptions-item-content {
        color: #ebf1ff;
        font-size: 13px;
      }
      .ant-descriptions-item-label {
        color: #a9b4cf;
        font-size: 12px;
      }
    }

    .small-header {
      color: #54bcff;
      font-weight: 400;
      padding-left: 9px;
      margin: 12px 0;

      &::before {
        background: #44c1efd1;
      }
    }

    .alert-item-container {
      background: #0f305c8c;
      height: 84px;
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #3894e8;
      border-radius: 8px;

      .content {
        flex: 1;
        height: 100%;

        .info-container {
          padding: 8px 12px;
          height: 49px;
          display: inline-grid;
          align-items: center;

          & > div {
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            color: #ebf1ff;
            cursor: default;
            font-size: 14px;
          }
        }

        .datetime-container {
          display: flex;
          justify-content: space-between;
          padding: 8px 12px;
          align-items: flex-end;
          cursor: default;
          .datetime-text {
            font-size: 13px;
            color: #a9b4cf;
          }

          .description-text {
            font-size: 11px;
            color: #a9b4cf;
          }
        }
      }

      .severity-tag {
        width: 50px;
      }
    }
  }
}
</style>
