<template>
<div style="width:418px;height:220px">
  <load-animation v-if="!finished" :height="220" :width="418"></load-animation>
  <dv-scroll-board v-else :config="config" style="width:418px;height:220px" ref="scrollBoard"/>
</div>
</template>

<script>
import '@/fonts/font.css'
import { getAlertList } from '@/api/alert'
import { severityColor } from '@/utils'
import LoadAnimation from '@/components/LoadAnimation'

export default {
  name: 'AlertTable',
  components: {
    LoadAnimation
  },
  data () {
    return {
      tableData: [],
      config: {
        header: ['告警名称', '告警等级', '告警时间'],
        data: [],
        headerBGC: 'rgba(67,110,189,0.3)',
        headerHeight: 36,
        columnWidth: [210, 70],
        waitTime: 3000
      },
      timer: null,
      total: 0,
      page: 1,
      finished: false
    }
  },
  mounted () {
    this.refresh()
  },
  beforeDestroy () {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
  },
  methods: {
    refresh () {
      this.fetchAll()
      this.timer = setInterval(() => {
        this.fetchAll()
      }, 1000 * 60 * 5)
    },
    fetch () {
      this.finished = false
      getAlertList({
        recovered: false,
        source_type: 'network_device',
        page: this.page,
        page_size: 10
      }).then(res => {
        const newData = res.data.data.map(item => {
          const severity = this.$t(`alert_severity.${item.severity}`)
          return [`<a-tooltip title="${item.name}"><span style="cursor: pointer;">${item.name}</span></a-tooltip>`, `<span style="color: ${this.getColor(item.severity)}; font-weight: 500; padding: 0 5px; border-radius: 4px; font-size: 12px;">${severity}</span>`, item.created_at]
        })
        this.tableData.push(...newData)
        this.total = res.data.total
        if (this.tableData.length < this.total) {
          this.page++
          this.fetch()
        } else this.config = { ...this.config, data: this.tableData }
        this.finished = true
      })
    },
    fetchAll () {
      this.page = 1
      this.total = 0
      this.tableData = []
      this.fetch()
    },
    getColor (level) {
      return severityColor(level)
    }
  }
}
</script>

<style lang="less">
.dv-scroll-board {
  .header {
    width: 418px;
    font-size: 14px !important;
    font-family: HanSans;
    color: #DBE4FE;
    line-height: 25px;
    margin-bottom: 5px;

    .header-item:nth-of-type(2){
      width: 130px !important;
      margin-left: 33px;
    }
  }
  .row-item {
    width: 418px;
    height: 36px;
    background: linear-gradient(90deg, #02236536, #053ca310) !important;
    font-size: 14px;
    font-family:  HanSans;
    color: #DBE4FE;
    line-height: 25px;
    opacity: 0.8;

    .A0 {
      background: #e32f46;
    }
    .A1 {
      background: #fa704d;
    }
    .A2 {
      background: #1a9bfc;
    }
    .A3 {
      background: #01babc;
    }
    .A4 {
      background: #99da69;
    }
    // margin-bottom: 8px;
  }
}
</style>
