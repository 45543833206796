<template>
  <div id="alert-count">
    <div style="height: 124px;">
      <count-card style="margin-left: 49px;" title="累计故障次数"></count-card>
      <count-card style="margin-left: 18px;" title="当月故障次数" type="month"></count-card>
      <div style="clear: both;"></div>
    </div>
    <little-header title="近 7 日故障变化趋势分布" titleWidth="180px"></little-header>
    <div style="padding: 0;">
      <alert-trend-chart></alert-trend-chart>
    </div>
  </div>
</template>

<script>
import LittleHeader from '@/components/LittleHeader'
import CountCard from './modules/CountCard'
import AlertTrendChart from './modules/ALertTrendChart'

export default {
  name: 'AlertCountModule',
  components: {
    LittleHeader,
    CountCard,
    AlertTrendChart
  }
}
</script>
