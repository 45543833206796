<template>
  <div id="source-module">
    <div style="height: 130px">
      <count-card title="设备总数" :value="sourceTotalCount" type="total"></count-card>
      <count-card
        title="设备在线数"
        :value="sourceNormalCount"
        type="online"
        style="margin-left: 62px"
      ></count-card>
      <div style="clear: both"></div>
    </div>
    <little-header title="设备利用率Top5"></little-header>
    <div class="btn-container">
      <div
        :style="{
          background:
            activeBtn === 'cpu'
              ? `url(${selectedImg})`
              : `url(${notSelectedImg})`,
        }"
        @click="handleClick('cpu')"
      >
        CPU 使用率
      </div>
      <div
        :style="{
          background:
            activeBtn === 'memory'
              ? `url(${selectedImg})`
              : `url(${notSelectedImg})`,
        }"
        @click="handleClick('memory')"
      >
        内存使用率
      </div>
    </div>
    <load-animation v-if="!finished" :height="290" :width="440"></load-animation>
    <div v-else class="source-top-container">
      <top-item
        v-for="(item, index) in topData"
        :key="item.name"
        :detail="item"
        :index="index + 1"
      ></top-item>
    </div>
  </div>
</template>

<script>
import '@/fonts/font.css'
import { getNetworkDeviceTop, getNetworkDeviceCount } from '@/api/network-device'
import LittleHeader from '@/components/LittleHeader'
import CountCard from './modules/CountCard'
import TopItem from './modules/TopItem'
import LoadAnimation from '@/components/LoadAnimation'

const keys = ['cpu', 'memory']

export default {
  name: 'SourceModule',
  components: {
    LittleHeader,
    CountCard,
    TopItem,
    LoadAnimation
  },
  mounted () {
    this.refresh()
  },
  data () {
    return {
      cardData: {
        all: 0,
        up: 0
      },
      activeBtn: 'cpu',
      selectedImg: require('@/assets/images/network-screen/btn-selected-bg.png'),
      notSelectedImg: require('@/assets/images/network-screen/btn-bg.png'),
      topData: [],
      topTimer: null,
      cpuTopData: [],
      memoryTopData: [],
      refreshTimer: null,
      sourceTotalCount: 0,
      sourceNormalCount: 0,
      finished: false
    }
  },
  methods: {
    refresh () {
      this.fetchCount()
      this.fetchTop()
      this.refreshTimer = setInterval(() => {
        this.fetchTop()
        this.fetchCount()
      }, 1000 * 60 * 5)
    },
    // fetch () {
    //   const params = { key_: 'icmpping' }
    //   getSourceCount(params).then((res) => {
    //     this.cardData = res.data
    //   })
    // },
    fetchCount () {
      getNetworkDeviceCount().then(res => {
        const data = res.data.data
        let total = 0
        let normal = 0
        data.forEach(item => {
          total += item.value
          if (item.name === 'normal') {
            normal += item.value
          }
        })
        this.sourceNormalCount = normal
        this.sourceTotalCount = total
      })
    },
    fetchTop () {
      this.finished = false
      this.activeBtn = 'cpu'
      Promise.all(keys.map(key => new Promise((resolve, reject) => {
        getNetworkDeviceTop({ top_of: key, limit: 5 }).then(res => {
          resolve(res.data.data)
        }).catch(err => reject(err))
      }))).then(res => {
        this.$emit('fetchStatus')
        this.finished = true
        this.cpuTopData = res[0]
        this.memoryTopData = res[1]
        this.topData = this.cpuTopData

        if (this.topTimer) clearInterval(this.topTimer)
        this.topTimer = setInterval(() => {
          if (this.activeBtn === 'cpu') {
            this.activeBtn = 'memory'
            this.topData = this.memoryTopData
          } else {
            this.topData = this.cpuTopData
            this.activeBtn = 'cpu'
          }
        }, 10000)
      })
    },
    handleClick (v) {
      if (v !== this.activeBtn) {
        this.activeBtn = v
        if (v === 'cpu') this.topData = this.cpuTopData
        else this.topData = this.memoryTopData
        if (this.topTimer) clearInterval(this.topTimer)
        this.topTimer = setInterval(() => {
          if (this.activeBtn === 'cpu') {
            this.activeBtn = 'memory'
            this.topData = this.memoryTopData
          } else {
            this.topData = this.cpuTopData
            this.activeBtn = 'cpu'
          }
        }, 6000)
      }
    }
  },
  beforeDestroy () {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
      this.refreshTimer = null
    }
    if (this.topTimer) {
      clearInterval(this.topTimer)
      this.topTimer = null
    }
  }
}
</script>

<style lang="less">
#source-module {
  .btn-container {
    display: flex;
    justify-content: space-evenly;
    margin-top: 26px;

    div {
      cursor: pointer;
      height: 32px;
      width: 123px;
      font-size: 14px;
      font-family: HanSans;
      color: #c4d7ee;
      line-height: 32px;
      text-align: center;
    }
  }

  .source-top-container {
    padding-top: 20px;
  }
}
</style>
