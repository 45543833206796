<template>
  <div id="alert-info">
    <load-animation v-if="!finished" :height="240" :width="418"></load-animation>
    <dv-scroll-ranking-board
      v-else-if="finished && detail.length"
      :config="config"
      style="width: 400px; height: 270px"
    />
    <p
      v-else-if="finished && !detail.length"
      style="text-align: center; line-height: 270px; color: rgba(255,255,255,.75)"
    >
      暂无数据
    </p>
  </div>
</template>

<script>
import { getAlertTop } from '@/api/alert'
import moment from 'moment'
import LoadAnimation from '@/components/LoadAnimation'

export default {
  name: 'AlertCountTop',
  components: { LoadAnimation },
  data () {
    return {
      config: { unit: '次', waitTime: 4000 },
      detail: [],
      timer: null,
      finished: false
    }
  },
  mounted () {
    this.refresh()
  },
  beforeDestroy () {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
  },
  methods: {
    refresh () {
      this.fetch()
      this.timer = setInterval(() => {
        this.fetch()
      }, 1000 * 60 * 5)
    },
    fetch () {
      this.finished = false
      getAlertTop({
        top_of: 'name',
        datetime_from: moment().subtract(6, 'day').format('YYYY-MM-DD HH:mm'),
        datetime_to: moment().format('YYYY-MM-DD HH:mm'),
        limit: 10,
        source_type: 'network_device'
      }).then(res => {
        this.detail = res.data.data.map(item => {
          return {
            name: `<a-tooltip title="${item.name}"><div style="color: #AEC3DC;font-family: D-DIN;font-size: 16px; cursor: pointer; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; line-height: 18px;">${item.name}</div></a-tooltip>`,
            value: item.value
          }
        })
        this.config = { ...this.config, data: this.detail }
      }).finally(() => {
        this.finished = true
      })
    }
  }
}
</script>

<style lang="less">
#alert-info {
  padding: 2px 0 0 24px;
  .ranking-info {
    .rank {
      // background: rgba(118, 154, 220, 0.18);
      color: #44c1ef;
      font-size: 16px;
      font-family: D-DIN;
      // text-align: center;
      margin-right: 6px;
    }

    .ranking-value {
      font-size: 16px;
      font-family: D-DIN;
      color: #83c9fe;
    }
  }

  .inside-column {
    background-image: linear-gradient(
      to right,
      rgba(3, 0, 0, 0.38),
      rgba(211, 211, 211, 0.213)
    );
    background-color: #1890ff;
  }
}
</style>
