<template>
  <div id="flow-module">
    <little-header :title="currentData.name || ''" titleWidth="160px"></little-header>
    <div style="display: flex; margin-top: 16px">
      <div class="count-card out-flow">
        <img
          src="@/assets/images/network-screen/5.png"
          width="150"
          height="150"
          style="position: absolute; bottom: -22px; left: -8px; z-index: -1"
        />
        <p class="flow-title">入向流量</p>
        <p class="flow-value">{{ currentData.outVal }}</p>
      </div>
      <div class="count-card in-flow">
        <img
          src="@/assets/images/network-screen/5.png"
          width="150"
          height="150"
          style="position: absolute; bottom: -22px; left: -8px; z-index: -1"
        />
        <p class="flow-title">出向流量</p>
        <p class="flow-value">{{ currentData.inVal }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import '@/fonts/font.css'
import { getDedicatedLineList } from '@/api/dedicated-line'
import { getNetworkDeviceMonitorHistory } from '@/api/network-device'
import { humanizeValue } from '@/utils'
import LittleHeader from '@/components/LittleHeader'

export default {
  name: 'FlowModule',
  components: {
    LittleHeader
  },
  data () {
    return {
      currentData: { name: '', inVal: 0, outVal: 0 },
      fetchParams: {
        page: 1,
        page_size: 15
      },
      dedicateLineList: [],
      total: 0,
      toggleTimer: null
    }
  },
  mounted () {
    this.fetchList()
  },
  beforeDestroy () {
    if (this.toggleTimer) clearInterval(this.toggleTimer)
  },
  methods: {
    fetchList () {
      getDedicatedLineList(this.fetchParams).then(res => {
        const data = res.data
        this.dedicateLineList.push(...data.data)
        this.total = data.total
        if (this.total > this.fetchParams.page * this.fetchParams.page_size) {
          this.fetchParams.page++
          this.fetchList()
          return
        }
        let index = 0
        if (index < this.dedicateLineList.length) {
          const promiseArr = []
          const keys = this.getKeys(this.dedicateLineList[index].network_interface.index)
          this.currentData.name = this.dedicateLineList[index].name
          keys.forEach(keyObj => {
            const p = new Promise((resolve, reject) => {
              getNetworkDeviceMonitorHistory(this.dedicateLineList[index].network_device.id, { key: keyObj.key }).then(res1 => {
                this.currentData[keyObj.dataIndex] = humanizeValue(res1.data.data.length ? res1.data.data[0].value : 0)
                resolve()
              }).catch((err) => { reject(err) })
            })
            promiseArr.push(p)
          })
          Promise.all(promiseArr).then(() => {
            this.toggleTimer = setInterval(async () => {
              index++
              if (index < this.dedicateLineList.length) {
                const promiseArr = []
                const keys = this.getKeys(this.dedicateLineList[index].network_interface.index)
                this.currentData.name = this.dedicateLineList[index].name
                keys.forEach(keyObj => {
                  const p = new Promise((resolve, reject) => {
                    getNetworkDeviceMonitorHistory(this.dedicateLineList[index].network_device.id, { key: keyObj.key }).then(res1 => {
                      this.currentData[keyObj.dataIndex] = humanizeValue(res1.data.data.length ? res1.data.data[0].value : 0)
                      resolve()
                    }).catch((err) => { reject(err) })
                  })
                  promiseArr.push(p)
                })
                await Promise.all(promiseArr)
              } else {
                clearInterval(this.toggleTimer)
                this.dedicateLineList = []
                this.fetchParams = {
                  page: 1
                }
                this.total = 0
                this.fetchList()
              }
            }, 5000)
          })
        }
      })
    },
    getKeys (index) {
      return [
        { key: `net.if.in[ifHCInOctets.${index}]`, dataIndex: 'inVal' },
        { key: `net.if.out[ifHCOutOctets.${index}]`, dataIndex: 'outVal' }
      ]
    }
  }
}
</script>

<style lang="less">
#flow-module {
  position: relative;
  .count-card {
    width: 136px;
    height: 120px;
    position: relative;
    margin-left: 50px;
    margin-top: 8px;
    text-align: center;

    &:nth-of-type(2) {
      margin-left: 85px;
    }

    .flow-title {
      padding-top: 2px;
      font-size: 16px;
      font-family: HanSans;
      color: #44c1ef;
      line-height: 32px;
      text-shadow: 1px 2px 0px rgba(17, 20, 22, 0.22);
      background: linear-gradient(
        0deg,
        rgba(250, 254, 255, 0.29) 0%,
        rgba(250, 254, 255, 0) 100%
      );
      -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;
    }
    .flow-value {
      font-size: 30px;
      font-family: D-DIN;
      font-weight: bold;
      color: #edfbff;

      background: linear-gradient(
        179deg,
        rgba(40, 217, 255, 0.5) 0%,
        rgba(255, 255, 255, 0.5) 100%
      );
      -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;
    }
  }
}
</style>
