<template>
  <div ref="networkScreenContainer"
    :style="{
      height: isFull ? '100vh' : 'calc(100vh - 142px)',
      width: isFull ? '100vw' : 'calc(100vw - 224px)',
      position: 'relative'
    }">
    <div id="network-screen" ref="networkScreen">
      <div class="now-time">{{ nowTime }}</div>
      <div class="home">
        <a @click="handleFullScreen">
          <template v-if="!isFull">
            <a-icon :component="fullIcon" style="font-size: 16px;"/>
            <span style="font-size: 14px; vertical-align: text-top; margin-left: 4px;">全屏展示</span>
          </template>
          <template v-else>
            <a-icon :component="notFullIcon"  style="font-size: 16px;"/>
            <span style="font-size: 14px; vertical-align: text-top; margin-left: 4px;">退出全屏</span>
          </template>
        </a>
      </div>

      <div class="screen-header-container">
        <header style="letter-spacing: 4px;">网络设备数据监控大屏</header>
      </div>

      <module-container
        style="top: 90px; left: 15px"
        height="409px"
        title="故障情况"
      >
        <alert-count-module></alert-count-module>
      </module-container>

      <module-container
        style="bottom: 0; left: 15px"
        height="582px"
        title="设备情况"
      >
        <source-module @fetchStatus="fetchStatus"></source-module>
      </module-container>

      <module-container
        style="top: 90px; right: 33px"
        height="761px"
        title="告警情况"
      >
        <alert-module></alert-module>
      </module-container>

      <div class="topology-menu-bg"></div>
      <div style=" position: absolute; height: 940px; width: 960px; top: 110px; left: 486px; overflow: hidden">
        <network-topology :comboLabel="comboLabel" :showTopology="showTopology"></network-topology>
      </div>

      <module-container
        style="bottom: 0; right: 33px"
        height="230px"
        title="网络出口"
      >

        <flow-module></flow-module>
      </module-container>
    </div>
  </div>
</template>

<script>
import '@/fonts/font.css'
import moment from 'moment'
import ModuleContainer from '@/components/ModuleContainer'
import AlertCountModule from './modules/AlertCountModule'
import SourceModule from './modules/SourceModule/index.vue'
import AlertModule from './modules/ALertModule/index.vue'
import FlowModule from './modules/FlowModule'
import NetworkTopology from './modules/NetworkTopology'

export default {
  name: 'NetworkBigScreen',
  components: {
    ModuleContainer,
    AlertCountModule,
    SourceModule,
    AlertModule,
    FlowModule,
    NetworkTopology
  },
  data () {
    return {
      designWidth: 1920,
      designHeight: 1080,
      screenBox: null,
      screenContainer: null,
      clientWidth: 0,
      clientHeight: 0,
      comboLabel: '',
      showTopology: false,
      nowTime: '',
      timer: null,
      isFull: false,
      fullIcon: {
        template: `
          <svg viewBox="0 0 1024 1024" width="1em" height="1em">
            <path d="M892.038 182.873l-708.65 708.65h190.333c18.99 0 34.384 15.447 34.384 34.5 0 19.054-15.394 34.5-34.384 34.5H98.644c-18.99 0-34.385-15.446-34.385-34.5v-276c0-9.15 3.623-17.925 10.071-24.395a34.326 34.326 0 0 1 24.314-10.105c9.119 0 17.865 3.635 24.313 10.105a34.559 34.559 0 0 1 10.071 24.395V840.06l706.425-706.425H651.346c-18.99 0-34.384-15.446-34.384-34.5 0-19.054 15.394-34.5 34.384-34.5h275.077c18.99 0 34.385 15.446 34.385 34.5v276c0 9.15-3.623 17.925-10.071 24.395a34.326 34.326 0 0 1-24.314 10.105 34.326 34.326 0 0 1-24.314-10.105 34.559 34.559 0 0 1-10.07-24.395V182.873zM99.385 410a34.326 34.326 0 0 1-24.314-10.105A34.559 34.559 0 0 1 65 375.5v-276C65 80.446 80.395 65 99.385 65h275.077c18.99 0 34.384 15.446 34.384 34.5 0 19.054-15.394 34.5-34.384 34.5H133.769v241.5c0 9.15-3.622 17.925-10.07 24.395A34.326 34.326 0 0 1 99.384 410z m825.23 552H649.538c-18.99 0-34.384-15.446-34.384-34.5 0-19.054 15.394-34.5 34.384-34.5h240.693V651.5c0-19.054 15.394-34.5 34.384-34.5 18.99 0 34.385 15.446 34.385 34.5v276c0 19.054-15.395 34.5-34.385 34.5z" fill="currentColor" p-id="2086"></path>
          </svg>
        `
      },
      notFullIcon: {
        template: `
          <svg viewBox="0 0 1024 1024" width="1em" height="1em">
            <path d="M379.336 697.237L153.362 921.55c-14.11 14.007-36.905 13.922-50.912-0.188-14.007-14.11-13.922-36.905 0.188-50.912l227.6-225.927H138.645c-18.99 0-34.385-15.446-34.385-34.5 0-19.053 15.395-34.5 34.385-34.5H413.72c18.99 0 34.384 15.447 34.384 34.5v276c0 9.15-3.622 17.926-10.07 24.396a34.326 34.326 0 0 1-24.314 10.104 34.326 34.326 0 0 1-24.314-10.104 34.559 34.559 0 0 1-10.071-24.396V697.237z m263.395-366.88l227.813-227.813c14.059-14.059 36.853-14.059 50.912 0 14.059 14.059 14.059 36.853 0 50.912l-225.18 225.18h187.147c18.99 0 34.385 15.445 34.385 34.5 0 19.053-15.395 34.5-34.385 34.5H608.346c-18.99 0-34.384-15.447-34.384-34.5v-276c0-9.15 3.622-17.926 10.07-24.396a34.326 34.326 0 0 1 24.314-10.105c9.12 0 17.865 3.635 24.314 10.105a34.559 34.559 0 0 1 10.07 24.395v193.223zM99.385 410a34.326 34.326 0 0 1-24.314-10.105A34.559 34.559 0 0 1 65 375.5v-276C65 80.446 80.395 65 99.385 65h275.077c18.99 0 34.384 15.446 34.384 34.5 0 19.054-15.394 34.5-34.384 34.5H133.769v241.5c0 9.15-3.622 17.925-10.07 24.395A34.326 34.326 0 0 1 99.384 410z m825.23 552H649.538c-18.99 0-34.384-15.446-34.384-34.5 0-19.054 15.394-34.5 34.384-34.5h240.693V651.5c0-19.054 15.394-34.5 34.384-34.5 18.99 0 34.385 15.446 34.385 34.5v276c0 19.054-15.395 34.5-34.385 34.5z" fill="currentColor" p-id="2240"></path>
          </svg>
        `
      }
    }
  },
  created () {
    this.timer = setInterval(() => {
      this.nowTime = moment().format('YYYY年MM月DD日 HH:mm:ss')
    }, 1000)
  },
  mounted () {
    this.onResize()
    window.addEventListener('resize', this.onResize)
    document.addEventListener('fullscreenchange', this.onFullScreenChange)
  },
  destroyed () {
    if (this.timer) clearInterval(this.timer)
    window.removeEventListener('resize', this.onResize)
    document.removeEventListener('fullscreenchange', this.onFullScreenChange)
  },
  methods: {
    onResize () {
      let scaleW = 0
      let scaleH = 0
      this.screenBox = this.$refs.networkScreen
      this.screenContainer = this.$refs.networkScreenContainer

      this.clientWidth = this.screenContainer.clientWidth
      this.clientHeight = this.screenContainer.clientHeight
      scaleW = this.clientWidth / this.designWidth
      scaleH = this.clientHeight / this.designHeight
      this.screenBox.style.transform = `scale(${scaleW},${scaleH})`
    },
    handleFullScreen () {
      const ele = document.fullscreenElement
      if (ele) {
        document.exitFullscreen()
      } else {
        this.screenContainer.requestFullscreen()
      }
      this.isFull = !document.fullscreenElement
    },
    onFullScreenChange () {
      this.isFull = Boolean(document.fullscreenElement)
      this.$nextTick(() => {
        this.onResize()
      })
    },
    fetchStatus () {
      this.showTopology = true
    }
  }
}
</script>

<style lang="less">
#network-screen {
  width: 1920px;
  height: 1080px;
  transform-origin: top left;
  color: #ffffff;
  font-family: "PingFang", sans-serif;
  background-image: url("../../assets/images/network-screen/screen-bg.png");
  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
  }

  // 全局单行文本省略
  .overflow-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  // 禁止文字选中
  .not-select {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -khtml-user-select: none;
    user-select: none;
  }
  p {
    margin: 0 !important;
  }

  // 大屏标题
  .screen-header-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1917px;
    height: 102px;
    position: absolute;
    left: 1.5px;
    top: 0;
    background: url("../../assets/images/network-screen/header-bg.png") no-repeat;
    background-size: auto;

    header {
      font-size: 32px;
      font-family: YouSheBiaoTiHei;
      color: #eff8fc;
      line-height: 22px;
      opacity: 0.89;
      text-shadow: 0px 4px 1px rgba(19, 80, 143, 0.66);

      background: linear-gradient(
        0deg,
        rgba(119, 186, 255, 0.45) 0%,
        rgba(233, 248, 255, 0.45) 73.3154296875%,
        rgba(255, 255, 255, 0.45) 100%
      );
      -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;
    }
  }

  // 模块标题
  .module-header-text {
    position: absolute;
    left: 50px;
    top: 2px;
    font-size: 26px;
    font-family: YouSheBiaoTiHei;
    color: #F6F9FE;
    text-shadow: 0px 2px 8px rgba(5,28,55,0.42);
    background: linear-gradient(0deg, rgba(14,197,236,0.36) 0%, rgba(49,190,255,0.36) 0%, rgba(239,252,254,0.36) 58.7646484375%);
    -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
  }

  .now-time {
    position: absolute;
    top: 28px;
    left: 34px;
    cursor: pointer;
    z-index: 999;
    color: rgb(203, 225, 244);
    font-size: 13px;
  }

  .home {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    z-index: 999;
  }

  .topology-menu-bg {
    position: absolute;
    top: 102px;
    left: 310px;
    width: 1308px;
    height: 45px;
    background-image: url('../../assets/images/network-screen/menu-bg.png');
    background-size: cover;

    // a {
    //   position: relative;
    //   left: 192px;
    //   top: -9px;
    //   cursor: pointer;

    //   img {
    //     margin-right: 8px;
    //   }

    //   span {
    //     font-size: 16px;
    //     font-family: HanSans;
    //     color: #CDDEF1;
    //     line-height: 24px;
    //     vertical-align: middle;
    //   }
    // }
  }
}

</style>
