import qs from 'qs'
import request from '@/utils/request'

const urlPrefix = '/dedicated-lines'

export function getDedicatedLine (id) {
  return request.get(`${urlPrefix}/${id}`)
}

export function updateDedicatedLine (id, data) {
  return request.put(`${urlPrefix}/${id}`, data)
}

export function deleteDedicatedLine (id) {
  return request.delete(`${urlPrefix}/${id}`)
}

export function getDedicatedLineList (params) {
  return request.get(urlPrefix, { params })
}

export function createDedicatedLine (data) {
  return request.post(urlPrefix, data)
}

export function exportDedicatedLine (params) {
  return request.get(`${urlPrefix}/export`, {
    params,
    paramsSerializer: obj => {
      return qs.stringify(obj, { arrayFormat: 'repeat' })
    },
    responseType: 'blob'
  })
}
