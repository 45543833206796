<template>
  <a-modal
    class="edge-info-modal"
    title="链路详情"
    :visible="visible"
    @cancel="handleCancel"
    :destroy-on-close="true"
    :width="900"
    :centered="true"
    :footer="null"
    :body-style="{
      background: '#031a37e6',
      color: 'rgba(255,255,255,.75)',
      minHeight: '350px'
    }"
  >
    <a-descriptions v-if="sNode" :column="7" style="margin-bottom: 8px">
      <a-descriptions-item label="设备1" :span="3">
        {{ sData.name }}
      </a-descriptions-item>
      <a-descriptions-item label="设备类型" :span="2">{{
        $t(`source_type.${sData.sourceType}`)
      }}</a-descriptions-item>
      <a-descriptions-item label="状态" :span="2">
        <source-status-tag
          :status="sData.status"
          style="vertical-align: bottom"
        ></source-status-tag>
      </a-descriptions-item>

      <a-descriptions-item label="设备2" :span="3">
        {{ tData.name }}
      </a-descriptions-item>
      <a-descriptions-item label="设备类型" :span="2">{{
        $t(`source_type.${tData.sourceType}`)
      }}</a-descriptions-item>
      <a-descriptions-item label="状态" :span="2">
        <source-status-tag
          :status="tData.status"
          style="vertical-align: bottom"
        ></source-status-tag>
      </a-descriptions-item>
    </a-descriptions>

    <a-table
      v-if="dataSource.length"
      :columns="columns"
      :data-source="dataSource"
      :loading="loading"
      :pagination="{pageSize: 5, size: 'small', total:dataSource.length,  showTotal: total => `共 ${total} 条`}"
      row-key="id"
    >
      <template slot="source" slot-scope="text, record">
        <source-status-tag
          v-if="record.sourcePortStatus"
          :status="record.sourcePortStatus"
          style="vertical-align: bottom; margin-right: 2px"
        ></source-status-tag>
        <template v-if="record.sourcePortName">
          <a-tooltip>
            <template #title>
              <div>设备：{{ record.sourceName }}</div>
              <div>网口：{{ record.sourcePortName }}</div>
            </template>
            <span>{{ record.sourcePortName }}</span>
          </a-tooltip>
        </template>
        <template v-else>
          <a-tooltip>
            <template #title>
              <div>设备：{{ record.sourceName }}</div>
            </template>
            <span>{{ record.sourceName }}</span>
          </a-tooltip>
        </template>
      </template>
      <template slot="target" slot-scope="text, record">
        <source-status-tag
          v-if="record.targetPortStatus"
          :status="record.targetPortStatus"
          style="vertical-align: bottom; margin-right: 2px"
        ></source-status-tag>
        <template v-if="record.targetPortName">
          <a-tooltip>
            <template #title>
              <div>设备：{{ record.targetName }}</div>
              <div>网口：{{ record.targetPortName }}</div>
            </template>
            <span>{{ record.targetPortName }}</span>
          </a-tooltip>
        </template>
        <template v-else>
          <a-tooltip>
            <template #title>
              <div>设备：{{ record.targetName }}</div>
            </template>
            <span>{{ record.targetName }}</span>
          </a-tooltip>
        </template>
      </template>
      <template slot="net_if_speed" slot-scope="text">
        <span style="color: #096dd9" v-if="text || text === 0">{{ text | human }}</span>
        <span style="color: #096dd9" v-else>-</span>
      </template>
      <template slot="net_if_in" slot-scope="text">
        <span style="color: #ffa450" v-if="text || text === 0">{{ text | human }}</span>
        <span style="color: #ffa450" v-else>-</span>
      </template>
      <template slot="net_if_out" slot-scope="text">
        <span style="color: #31d0c6" v-if="text || text === 0">{{ text | human }}</span>
        <span style="color: #31d0c6" v-else>-</span>
      </template>
    </a-table>
  </a-modal>
</template>

<script>
import SourceStatusTag from '@/components/tag/SourceStatusTag'
import { humanizeValue } from '@/utils'

export default {
  name: 'EdgeInfoModal',
  components: {
    SourceStatusTag
  },
  data () {
    return {
      visible: false,
      loading: false,
      mainEdge: undefined,
      graph: null,
      edges: [], // 当前的所有连线
      sNode: undefined,
      tNode: undefined,
      sData: {},
      tData: {},
      dataSource: [],
      columns: [
        {
          dataIndex: 'sourceName',
          title: '链路出口',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'source'
          }
        },
        {
          dataIndex: 'targetName',
          title: '链路入口',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'target'
          }
        },
        {
          dataIndex: 'net_if_speed',
          title: '速率 (bps)',
          width: 100,
          align: 'center',
          scopedSlots: {
            customRender: 'net_if_speed'
          }
        },
        {
          dataIndex: 'net_if_in',
          title: '入流量 (bps)',
          width: 115,
          align: 'center',
          scopedSlots: {
            customRender: 'net_if_in'
          }
        },
        {
          dataIndex: 'net_if_out',
          title: '出流量 (bps)',
          width: 115,
          align: 'center',
          scopedSlots: {
            customRender: 'net_if_out'
          }
        }
      ]
    }
  },
  methods: {
    show (edges, sourceNode, targetNode, graph) {
      this.graph = graph
      this.sNode = sourceNode
      this.tNode = targetNode
      this.sData = this.sNode.getData()
      this.tData = this.tNode.getData()
      this.loading = true
      edges.forEach(edge => {
        if (edge.visible) this.mainEdge = edge
        else this.edges.push(edge)
      })

      const tableEdges = this.edges

      Promise.all(
        tableEdges.map(
          edge =>
            new Promise((resolve, reject) => {
              const sourceNode = edge.getSourceNode()
              const targetNode = edge.getTargetNode()
              const source = edge.source
              const target = edge.target
              const edgeData = edge.getData()

              const obj = {
                id: edge.id,
                sourceName: sourceNode.data.name,
                sourcePortName: '',
                targetName: targetNode.data.name,
                targetPortName: '',
                net_if_in: edgeData.net.in,
                net_if_out: edgeData.net.out,
                net_if_speed: edgeData.net.speed,
                usage: edgeData.net.usage,
                sourcePortStatus: edgeData.portStatus.source,
                targetPortStatus: edgeData.portStatus.target
              }
              if (source.port) {
                obj.sourcePortName = sourceNode.getPort(source.port).name
              }
              if (target.port) {
                obj.targetPortName = targetNode.getPort(target.port).name
              }
              resolve(obj)
            })
        )
      ).then(res => {
        this.dataSource = res
        this.loading = false
      })
      this.visible = true
    },
    handleCancel () {
      this.visible = false
      this.mainEdge = undefined
      this.edges = []
      this.sNode = undefined
      this.tNode = undefined
      this.sData = {}
      this.tData = {}
      this.dataSource = []
    }
  },
  filters: {
    human (value) {
      return humanizeValue(value)
    }
  }
}
</script>

<style lang="less">
.edge-info-modal {
  .ant-modal-content {
    background: transparent;
    color: rgba(255, 255, 255, 0.75);

    .ant-modal-close {
      color: rgba(255, 255, 255, 0.75);
    }

    .ant-modal-header {
      background: #031a37e6;
      color: rgba(255, 255, 255, 0.75);
      border-color: #22b2e2;

      .ant-modal-title {
        color: rgba(255, 255, 255, 0.75);
      }
    }

    .ant-descriptions-item-content {
      color: rgba(255, 255, 255, 0.85);
    }
    .ant-descriptions-item-label {
      color: rgba(255, 255, 255, 0.65);
      font-size: 13px;
    }

    .ant-table-body {
      background: transparent !important;
      color: rgba(255, 255, 255, 0.65);
      .ant-table-thead > tr > th {
        background: #214f8e3d;
        padding: 8px 16px;
        border: none !important;
        color: rgba(255, 255, 255, 0.85);
        font-weight: 500;
      }
      .ant-table-tbody > tr > td {
        background: transparent;
        border: none;
      }
    }

    .ant-table-pagination {
      color: rgba(255, 255, 255, 0.65);

      .ant-pagination-prev,
      .ant-pagination-next {
        color: rgba(255, 255, 255, 0.65);

        .ant-pagination-item-link {
          color: rgba(255, 255, 255, 0.65);
        }
      }

      .ant-pagination-item {
        border: none;

        a {
          color: rgba(255, 255, 255, 0.65);
        }
      }
      .ant-pagination-item-active {
        background: none;

       a {
        color: rgb(27, 144, 227);
        font-weight: 500;
       }
      }
    }
  }
}
</style>
