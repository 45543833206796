<template>
  <div id="little-title-container">
    <div class="little-title-border"></div>
    <div class="little-title" :style="{ width: titleWidth }">{{ title }}</div>
    <div class="little-title-border"></div>
  </div>
</template>

<script>
import '@/fonts/font.css'

export default {
  name: 'LittleHeader',
  props: {
    title: {
      type: String
    },
    titleWidth: {
      type: String,
      default: '120px'
    }
  }
}
</script>

<style lang="less">
#little-title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;

  .little-title {
    margin: 0 10px 2px 8px;
    text-align: center;
    font-size: 16px;
    font-family: HanSans;
    font-weight: 500;
    font-style: italic;
    color: #ffffff;
    background: linear-gradient(
      0deg,
      rgba(98, 182, 241, 0.86) 0.3662109375%,
      rgba(255, 255, 255, 0.86) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .little-title-border {
    height: 1px;
    background: linear-gradient(to left, transparent, #5e8ebb);
    flex: 1;

    &:nth-of-type(1) {
      background: linear-gradient(to right, transparent, #5e8ebb);
      flex: 1;
    }
  }
}
</style>
