<template>
  <div class="network-topology-screen" id="network-topology-screen">
    <div class="topology-menu-container">
      <div class="video-btn-comtainer">
        <a @click="togglePlay">
          <pause-icon v-show="isPlay"></pause-icon>
          <play-icon v-show="!isPlay"></play-icon>
        </a>
      </div>
      <a-tabs
        id="radio-button"
        :activeKey="activeTopologyId"
        @change="toggleTopology"
        :tabBarStyle="{
          borderBottom: 'none',
          margin: '0px',
        }"
      >
        <a-tab-pane
          v-for="item in topologyList"
          :key="item.id"
          :tab="item.name"
        ></a-tab-pane>
      </a-tabs>
    </div>
    <div class="tools-container">
      <div class="left-container">
        <div
          style="
            width: 170px;
            height: 50px;
            display: flex;
            justify-content: space-between;
          "
        >
          <div class="icon-container">
            <alert-icon style="color: #e32f46; font-size: 19px"></alert-icon>
          </div>
          <div class="value-container">{{ abnormalCount }}</div>
          <div class="unit-container">异常</div>
        </div>
        <div
          style="
            width: 170px;
            height: 50px;
            display: flex;
            justify-content: space-between;
          "
        >
          <div class="icon-container icon-container-normal">
            <alert-icon style="color: #4da40e; font-size: 19px"></alert-icon>
          </div>
          <div class="value-container value-container-normal">
            {{ normalCount }}
          </div>
          <div class="unit-container">正常</div>
        </div>
      </div>
      <a-space size="large">
        <a-space>
          <div
            :style="{
              borderRadius: '4px',
              background: EDGE_RED,
              width: '12px',
              height: '5px',
              lineHeight: '14px',
              textAlign: 'center'
            }"
          ></div>
          <div style="font-size: 11px">有告警 / 流量超过90%</div>
        </a-space>
        <a-space>
          <div
            :style="{
              borderRadius: '4px',
              background: EDGE_YELLOW,
              width: '12px',
              height: '5px',
              lineHeight: '14px',
              textAlign: 'center'
            }"
          ></div>
          <div style="font-size: 11px">流量低于90%</div>
        </a-space>
        <a-space>
          <div
            :style="{
              borderRadius: '4px',
              background: EDGE_GREEN,
              width: '12px',
              height: '5px',
              lineHeight: '14px',
              textAlign: 'center'
            }"
          ></div>
          <div style="font-size: 11px">流量低于60%</div>
        </a-space>
        <a-space>
          <div
            :style="{
              borderRadius: '4px',
              background: EDGE_GRAY,
              width: '12px',
              height: '5px',
              lineHeight: '14px',
              textAlign: 'center'
            }"
          ></div>
          <div style="font-size: 11px">无数据</div>
        </a-space>
      </a-space>
    </div>
    <load-animation v-if="!finished"></load-animation>
    <div id="detail-topology"></div>
    <edge-info-modal ref="edgeInfoModal"></edge-info-modal>

    <div class="node-tooltip-container" v-show="showNodeTooltip && selectNode">
      <node-tooltip
        :select-node="selectNode"
        @closeNodeTooltip="
          {
            selectNode = undefined
            showNodeTooltip = false
          }
        "
      ></node-tooltip>
    </div>
  </div>
</template>

<script>
import { Graph } from '@antv/x6'
import {
  initNodeStyle,
  initEdges,
  getConnectEdges,
  EDGE_GRAY,
  EDGE_RED,
  EDGE_YELLOW,
  EDGE_GREEN
} from '@/utils/networkTopology'
import {
  getNetworkTopologyList,
  getNetworkTopology
} from '@/api/network-topology'
import EdgeInfoModal from './modules/EdgeInfoModal.vue'
import AlertIcon from '@/components/icon/AlertIcon'
import NodeTooltip from './modules/NodeTooltip'
import store from '@/store'
import PauseIcon from '@/components/icon/PauseIcon'
import PlayIcon from '@/components/icon/PlayIcon'
import LoadAnimation from '@/components/LoadAnimation'

export default {
  name: 'NetworkTopology',
  props: {
    showTopology: {
      type: Boolean,
      default: false
    }
  },
  components: { EdgeInfoModal, AlertIcon, NodeTooltip, PauseIcon, PlayIcon, LoadAnimation },
  data () {
    return {
      isPlay: false,
      activeTopologyId: '',
      topologyList: [],
      detail: {},
      graph: null,
      canOperate: false,
      normalCount: 0,
      abnormalCount: 0,
      EDGE_GRAY,
      EDGE_RED,
      EDGE_YELLOW,
      EDGE_GREEN,
      showNodeTooltip: false,
      selectNode: undefined,
      tabTimer: null,
      currentIndex: 0,
      finished: false,
      timer: null
    }
  },
  mounted () {
    this.initGraph()
    this.refresh()
  },
  methods: {
    refresh () {
      this.fetch()
      this.timer = setInterval(() => {
        this.fetch()
      }, 1000 * 60 * 5)
    },
    fetch () {
      getNetworkTopologyList().then(res => {
        this.topologyList = res.data.data.filter(item => item.completed)
        if (this.topologyList.length) {
          this.currentIndex = 0
          this.fetchDetail()
        }
      })
    },
    fetchDetail () {
      this.finished = false
      this.activeTopologyId = this.topologyList[this.currentIndex].id
      this.canOperate = false
      this.normalCount = 0
      this.abnormalCount = 0
      getNetworkTopology(this.activeTopologyId)
        .then(async res => {
          this.detail = res.data
          await initNodeStyle(this.detail.nodes, this)
          this.graph.fromJSON({ nodes: this.detail.nodes })
          this.graph.zoomToFit({ maxScale: 1.2, minScale: 0.6, padding: 60 })

          setTimeout(() => {
            this.finished = true
          }, 2000)

          // 渲染边
          initEdges(this.graph, this.detail.edges).then(() => {
            this.canOperate = true
            const nodes = this.graph.getNodes()
            nodes.forEach(node => {
              if (
                node.data.status === 'normal' ||
                node.data.status === 'unknown'
              ) {
                this.normalCount++
              } else {
                this.abnormalCount++
              }
            })

            if (this.isPlay) {
              if (this.tabTimer) clearTimeout(this.tabTimer)
              this.tabTimer = setTimeout(() => {
                if (this.currentIndex < this.topologyList.length - 1) {
                  this.currentIndex++
                } else this.currentIndex = 0
                this.fetchDetail()
              }, 1000 * 30)
            } else {
              if (this.tabTimer) clearTimeout(this.tabTimer)
            }
          }).finally(() => {
            this.canOperate = true
          })
        })
        .catch(() => {})
    },
    initGraph () {
      const container = document.getElementById('detail-topology')
      this.graph = new Graph({
        container,
        autoResize: true,
        panning: true,
        grid: false,
        interacting: false,
        mousewheel: true,
        scaling: {
          min: 0.3,
          max: 2
        }
      })
      this.graph.on('edge:mouseenter', ({ edge }) => {
        edge.attr('line/strokeDasharray', 0)
      })

      this.graph.on('edge:mouseleave', ({ edge }) => {
        edge.attr('line/strokeDasharray', 8)
      })

      this.graph.on('node:click', ({ node }) => {
        this.showNodeTooltip = true
        this.selectNode = node
      })

      this.graph.on('edge:click', ({ edge }) => {
        if (this.canOperate) {
          this.$refs.edgeInfoModal.show(
            getConnectEdges(this.graph, edge),
            edge.getSourceNode(),
            edge.getTargetNode(),
            this.graph
          )
        }
      })
    },
    getBtnClass (id) {
      return {
        'menu-btn-container': true,
        'overflow-ellipsis': true,
        active: this.activeTopologyId === id
      }
    },
    toggleTopology (topologyId) {
      if (this.showTopology) {
        if (topologyId !== this.activeTopologyId) {
        // 获取到index并同步
          this.activeTopologyId = topologyId
          this.currentIndex = this.topologyList.findIndex(item => item.id === this.activeTopologyId)
          this.normalCount = 0
          this.abnormalCount = 0
          if (this.tabTimer) clearTimeout(this.tabTimer)
          store.state.cancelRequest.promiseArr.forEach((e, index) => {
            e.cancel('取消请求')
          })
          store.commit('cancelRequest/CLEAR_PROMISE_ARR')
          this.fetchDetail()
        }
      }
    },
    togglePlay () {
      if (this.showTopology) {
        this.isPlay = !this.isPlay
        if (this.isPlay) {
          if (this.tabTimer) clearTimeout(this.tabTimer)
          this.tabTimer = setTimeout(() => {
            if (this.currentIndex < this.topologyList.length - 1) {
              this.currentIndex++
            } else this.currentIndex = 0
            this.fetchDetail()
          }, 1000 * 10)
        } else {
          if (this.tabTimer) clearTimeout(this.tabTimer)
        }
      }
    }
  },
  beforeDestroy () {
    if (this.tabTimer) clearTimeout(this.tabTimer)
    if (this.timer) clearInterval(this.timer)
  }
}
</script>

<style lang="less">
.network-topology-screen {
  width: 960px;
  height: 940px;
  position: absolute;
  left: 0;
  top: 13px;

  .topology-menu-container {
    display: flex;
    #radio-button {
      flex: 1;
      .ant-tabs-nav-container {
        margin: 0 !important;
        height: 42px;
        .ant-tabs-nav-wrap {
          .ant-tabs-tab {
            width: 140px;
            height: 42px;
            background-image: url("~@/assets/images/network-screen/默认.png");
            font-size: 18px;
            color: #eff2ff;
            font-family: YouSheBiaoTiHei;
            line-height: 20px;
            text-align: center;
          }
          .ant-tabs-tab-active {
            background-image: url("~@/assets/images/network-screen/选中.png");
          }
          .ant-tabs-ink-bar {
            width: 0 !important;
          }
        }
        .ant-tabs-tab-arrow-show {
          color: #31b7ff;
        }
      }
    }
    .video-btn-comtainer {
      height: 42px;
      width: 32px;
      font-size: 24px;
      line-height: 42px;
      a {
        color: #4c9df4c5;
      }
    }
  }
  #detail-topology {
    width: 960px;
    height: 820px;
    margin-top: 80px;

    .network-topology-node-title {
      color: #c4d7ee !important;
    }

    img {
      cursor: pointer;
    }

    .x6-port,
    .x6-port-body {
      cursor: default !important;
    }
  }
  @keyframes topology-edge {
    to {
      stroke-dashoffset: -1000;
    }
  }

  .tools-container {
    position: absolute;
    top: 56px;
    height: 60px;
    left: 0px;
    // background: red;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;

    .left-container {
      display: flex;
      background: linear-gradient(
        to right,
        transparent,
        #014384 50%,
        transparent
      );
      width: 340px;
      height: 50px;
      padding-right: 16px;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        top: 1px;
        left: 0;
        background: linear-gradient(
          to right,
          transparent,
          #0296d0 50%,
          transparent
        );
      }
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: 1px;
        left: 0;
        background: linear-gradient(
          to right,
          transparent,
          #0296d0 50%,
          transparent
        );
      }
      .icon-container {
        width: 35px;
        height: 35px;
        margin-top: 8px;
        margin-left: 16px;
        border-radius: 50%;
        text-align: center;
        line-height: 35px;
        background: radial-gradient(circle at center, #e32f4600 30%, #e32f4690);
      }
      .icon-container-normal {
        background: radial-gradient(circle at center, #4da40e00 30%, #4da40e90);
      }
      .value-container {
        font-size: 24px;
        line-height: 53px;
        padding-left: 12px;
        color: #e64c60;
      }
      .value-container-normal {
        color: #78b84a;
      }
      .unit-container {
        font-size: 11px;
        color: #c4d7ee;
        line-height: 65px;
      }
    }
  }

  .node-tooltip-container {
    position: absolute;
    width: 330px;
    height: 408px;
    padding: 12px 16px;
    bottom: 28px;
    left: 10px;
    z-index: 999;
    &::after {
      content: '';
      background-image: url('~@/assets/images/tooltip-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 330px;
      height: 408px;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0.85;
      z-index: -1;
    }
  }
}
</style>
