<template>
  <div id="module-container" :style="style">
    <div class="container-module-header">
      <header class="module-header-text">{{ title }}</header>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ModuleContainer',
  props: {
    width: {
      type: String,
      default: '447px'
    },
    height: {
      type: String,
      default: '450px'
    },
    title: {
      type: String
    }
  },
  computed: {
    style () {
      const style = {
        height: this.height,
        width: this.width
      }
      return style
    }
  }
}
</script>

<style lang="less">
#module-container {
  position: absolute;

  .container-module-header {
    position: relative;
    height: 48px;
    background: url("../assets/images/network-screen/module-header-bg.png") no-repeat;
  }
}
</style>
