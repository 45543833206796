<template>
  <div class="source-count-card">
    <img :src="src" width="72" height="72" style="margin-top: 3px;"/>
    <div class="value-container">
      <p class="source-count-title">{{ title }}</p>
      <p class="source-count-value">
        <span class="value">{{ value }}</span>
        <span class="unit">个</span>
      </p>
    </div>
  </div>
</template>

<script>
import '@/fonts/font.css'

export default {
  name: 'CountCard',
  props: {
    title: {
      type: String
    },
    value: {
      type: Number
    },
    type: {
      type: String
    }
  },
  computed: {
    src () {
      if (this.type === 'total') return require('@/assets/images/network-screen/2.png')
      return require('@/assets/images/network-screen/3.png')
    }
  }
}
</script>

<style lang="less">
.source-count-card {
  float: left;
  position: relative;
  margin-left: 50px;
  margin-top: 27px;
  .value-container {
    float: right;
    padding-left: 15px;
    padding-top: 10px;

    .source-count-title {
      font-size: 16px;
      font-family: HanSans;
      font-weight: 400;
      color: #a2b7d9;
      margin-bottom: 12px;
    }
    .source-count-value {
      position: relative;
      font-size: 24px;
      font-family: D-DIN;
      font-weight: bold;
      color: #c9dfff;

      background: linear-gradient(
        0deg,
        rgba(3, 0, 0, 0.29) 0%,
        rgba(255, 255, 255, 0.29) 100%
      );
      -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;

      .value {
        font-size: 24px;
        font-family: D-DIN;
        font-weight: bold;
        color: #c9dfff;

        background: linear-gradient(
          0deg,
          rgba(3, 0, 0, 0.29) 0%,
          rgba(255, 255, 255, 0.29) 100%
        );
        -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
      }

      .unit {
        position: absolute;
        font-size: 15px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #98a9c5;
        right: 0;
        bottom: 6px;
      }
    }
  }
}
</style>
