<template>
  <div id="top-item">
    <div style="width: 421px; position: relative; height: 38px">
      <div class="name-container">
        <div :class="index > 3 ? 'index-bg index' : 'index-img index'">
          TOP{{ index }}
        </div>
        <div class="name-text overflow-ellipsis">{{ detail.name }}</div>
      </div>
      <div class="value-container">{{ formatter(detail.value) }} %</div>
      <div style="position: absolute; bottom: -8px; width: 421px; left: 2px">
        <a-progress
          :stroke-width="3"
          stroke-linecap="square"
          :percent="detail.value * 1"
          :show-info="false"
          size="small"
          status="active"
          :stroke-color="{
            from: 'rgba(3,0,0,0.38)',
            to: 'rgba(255,255,255,0.38)',
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import '@/fonts/font.css'

export default {
  name: 'TopItem',
  props: {
    detail: {
      type: Object,
      required: true
    },
    index: {
      type: Number
    }
  },
  methods: {
    formatter (v) {
      return parseInt(v * 100) / 100
    }
  }
}
</script>

<style lang="less">
.source-top-container {
  #top-item {
    padding: 0 10px 8px 29px;
    margin-bottom: 7px;
    .name-container {
      float: left;
      .index {
        text-align: center;
        font-size: 16px;
        font-family: D-DIN;
        float: left;
        margin-right: 12px;
      }
      .index-img {
        width: 60px;
        height: 32px;
        line-height: 32px;
        background: url("../../../../../assets/images/network-screen/top-bg.png");
        color: #d1e4ff;
      }
      .index-bg {
        width: 54px;
        height: 26px;
        line-height: 26px;
        margin: 3px 15px 0 3px;
        background: rgba(118, 154, 220, 0.18);
        color: #b6d8ff;
      }

      .name-text {
        font-size: 16px;
        font-family: D-DIN;
        color: #aec3dc;
        float: left;
        line-height: 32px;
        max-width: 280px;
      }
    }
    .value-container {
      float: right;
      font-size: 18px;
      font-family: D-DIN;
      color: #83c9fe;
      // line-height: 58px;
    }

    .ant-progress-inner {
      background: #80a4e530;
    }
  }
}
</style>
