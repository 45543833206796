<template>
  <div class="count-card">
    <img src="../../../../../assets/images/network-screen/1.png">
    <p class="count-card-title">{{title}}</p>
    <div class="count-card-value-container">
      <span class="count-card-value">{{value | formatterValue}}</span>
      <span class="count-card-unit">{{unit}}</span>
    </div>
  </div>
</template>

<script>
import '@/fonts/font.css'
import { getAlertCount } from '@/api/alert'
import moment from 'moment'

export default {
  name: 'CountCard',
  props: {
    title: {
      type: String
    },
    type: {
      type: String,
      default: 'total'
    }
  },
  data () {
    return {
      value: 0,
      timer: null,
      unit: '次'
    }
  },
  beforeDestroy () {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
  },
  mounted () {
    this.refresh()
  },
  methods: {
    refresh () {
      this.fetch()
      this.timer = setInterval(() => {
        this.fetch()
      }, 1000 * 60 * 5)
    },
    fetch () {
      if (this.type === 'total') {
        const params = { source_type: 'network_device' }
        getAlertCount(params).then((res) => {
          res.data.data.forEach(item => {
            this.value += item.value
          })
          if (this.value > 10000) this.unit = '万次'
        })
      } else {
        // 当月
        const params = {
          source_type: 'network_device',
          datetime_from: moment().startOf('months').format('YYYY-MM-DD HH:mm'),
          datetime_to: moment().format('YYYY-MM-DD HH:mm')
        }
        getAlertCount(params).then((res) => {
          res.data.data.forEach(item => {
            this.value += item.value
          })
          if (this.value > 10000) this.unit = '万次'
        })
      }
    }
  },
  filters: {
    formatterValue (v) {
      if (v >= 10000) {
        return (v / 1000).toFixed(2)
      }
      return v
    }
  }
}
</script>

<style lang="less">
#alert-count{
  .count-card {
  position: relative;
  width: 186px;
  height: 76px;
  float: left;
  margin-top: 26px;

  img {
    position: absolute;
    top: -5px;
    left: -12px;
  }

  .count-card-title {
    position: absolute;
    width: 97px;
    height: 16px;
    font-size: 16px;
    font-family: HanSans;
    font-weight: 400;
    font-style: italic;
    color: #FFFFFF;
    line-height: 28px;
    text-shadow: 1px 2px 0px rgba(17,20,22,0.22);
    top: 2px;
    left: 70px;
  }

  .count-card-value-container {
    position: absolute;
    right: 44px;
    bottom: 6px;
    width: 68px;
  }

  .count-card-value {
    font-size: 24px;
    font-family: D-DIN;
    color: #FF9C66;
    background: linear-gradient(-5deg, rgba(3,0,0,0.15) 0%, rgba(255,255,255,0.15) 100%);
    -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
  }

  .count-card-unit {
    position: absolute;
    right: -12px;
    bottom: 14px;
    height: 13px;
    font-size: 14px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    font-style: italic;
    color: #7BBBE7;
  }
}
}
</style>
